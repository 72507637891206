.ShowcaseCourses {
  display: flex;
  position: fixed;
  z-index: 100000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.8);

  @include respond(phone) {
    display: flex;
    display: -webkit-flexbox;
  }

  &__Container {
    background-color: #ffffff;
    margin: auto;
    border: 0.1rem solid #888;
    width: 40%;
    border-radius: 1%;

    @include respond(phone) {
      background-color: #ffffff;
      margin: auto;
      border: none;
      // margin-top: 30rem;
      width: 80%;
      border-radius: 8px;
      padding: 3rem;
    }

    &--Para1 {
      font-style: normal;
      font-weight: normal;
      font-size: 1.8rem;
      line-height: 150%;
      color: rgba(0, 0, 0, 0.87);
      mix-blend-mode: normal;
      margin-bottom: 1.6rem;
    }

    &--Para2 {
      font-style: normal;
      font-weight: normal;
      font-size: 1.6rem;
      line-height: 150%;
      color: rgba(0, 0, 0, 0.6);
      mix-blend-mode: normal;
      margin-bottom: 2.4rem;
    }

    &--Btn {
      background: #2c7478;
      border: 1px solid #2c7478;
      box-sizing: border-box;
      border-radius: 4px;
      font-style: normal;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2rem;
      color: #ffffff;
      width: 100%;
      padding: 1.2rem 0;
      margin: 0.2rem;
    }
    &--Btn2 {
      background: #e64b4b;
      border: 1px solid #e64b4b;
      box-sizing: border-box;
      border-radius: 4px;
      font-style: normal;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 2rem;
      color: #ffffff;
      width: 100%;
      padding: 1.2rem 0;
    }
  }
}
