.navigation {
  padding: 1.2rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $color-primary-white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 100;

  &__logo {
    width: 16rem;
    height: 4rem;

    @include respond(phone) {
      width: 14rem;
      height: 3.5rem;
    }

    &--img {
      margin-left: 10rem;
      width: 100%;
      height: 100%;
      @include respond(phone) {
        margin-left: 1rem;
      }
    }

    &--btn {
      width: 100%;
      height: 100%;
    }
  }

  &--btn {
    padding: 1rem 2.4rem;
    font-size: 1.6rem;
    border-radius: 4px;
    background-color: #009ae0;
    color: #ffffff;
    outline: none;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease-in;
    margin-right: 10rem;

    @include respond(phone) {
      margin-right: 1rem;
      padding: 1rem 2.4rem;
      // font-weight: $font-weight-medium;
      // font-size: 14px;
      // line-height: 16px;
      // background-color: #ffffff;
      // color: #009ae0;
      // transition: none;
    }
  }
}

.scrollButton {
  border-radius: 50px;
  font-weight: 600;
  padding: 1rem 3.1rem;
}

.disable {
  display: flex;
  @include respond(phone) {
    display: none;
  }
}

.mobileDisable {
  display: none;
  @include respond(phone) {
    display: flex;
  }
}
