.AffiliateDescription {
  background: #f7fafc;
  display: flex;
  flex-direction: column;
  padding: 3rem;

  &__Italic {
    font-style: italic;
    font-weight: 450;
    font-size: 1.8rem;
    line-height: 150%;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
  }

  &__Para1 {
    text-align: center;
    margin-bottom: 3.5rem;
    color: rgba(0, 0, 0, 0.87);
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 3.6rem;
    margin-top: 2.5rem;
    color: #2d2d45;
  }

  &__Para2,
  &__Para3 {
    text-align: center;
    font-size: 1.8rem;
    line-height: 150%;
    color: rgba(0, 0, 0, 0.6);
  }
  &__Para2 {
    margin-top: 2rem;
  }
  &__Para3 {
    margin-top: 4.3rem;
  }
}
