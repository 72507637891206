.AffiliateWhy {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3.2rem 3.2rem 4.2rem;
  background-image: url("../../images/coding-store/Background\ \(7\).svg");
  background-size: cover;
  background-repeat: no-repeat;

  &__Header {
    width: 100%;
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 2.4rem;
    line-height: 3.6rem;
    color: #2d2d45;
    margin-top: 2.5rem;
    font-weight: bold;
  }

  &__SubHeader {
    font-weight: 450;
    font-size: 1.8rem;
    line-height: 150%;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
    margin-top: 2.4rem;
  }

  &__Div {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin-top: 7.6rem;
    justify-content: space-between;

    @include respond(phone) {
      flex-wrap: wrap;
      align-items: center;
      margin-top: 0rem;
      width: 100%;
    }

    &--Col {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      vertical-align: top;

      @include respond(phone) {
        margin-top: 3rem;
        // width: 45%;
      }

      &_Header {
        font-weight: 500;
        font-size: 2.4rem;
        font-weight: bold;
        line-height: 3.6rem;
        text-align: center;
        color: rgba(0, 0, 0, 0.87);
      }

      &_Para2 {
        margin-top: 2rem;
        font-style: normal;
        font-weight: 450;
        font-size: 1.6rem;
        line-height: 2.7rem;
        text-align: center;
        color: rgba(0, 0, 0, 0.6);
      }
    }

    &--Col img {
      width: 30%;
    }
  }
}

.Information {
  display: flex;
  justify-content: center;
  padding: 2.6rem 0;
  &__Main {
    background-image: url("../../images/coding-store/Background\ \(6\).svg");
    padding: 1.8rem 2.4rem;
    border-radius: 8px;
    width: 75%;
    font-style: normal;
    font-weight: 800;
    font-size: 2.4rem;
    line-height: 150%;
    text-align: center;
    color: #ffffff;
  }
}

.ContactUs {
  padding: 6rem 3.2rem;
  background: #f7fafc;

  &__Header {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 3.6rem;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    padding-bottom: 2.4rem;
  }

  &__Para {
    font-weight: 450;
    font-size: 1.8rem;
    line-height: 150%;
    text-align: center;
    color: rgba(0, 0, 0, 0.6);
  }
}

.darkColor {
  color: #333333;
  font-weight: 600;
}
