.CodingNews {
  padding: 3.2rem;
  position: relative;

  &__Header {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 3.6rem;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 1.6rem;
  }

  &__Content {
    height: 32rem;
    background: #ffffff;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
    border-radius: 4px;

    &--Img {
      width: 100%;
    }

    &--Para {
      font-weight: 450;
      font-size: 2rem;
      line-height: 150%;
      font-feature-settings: "liga" off;
      color: rgba(0, 0, 0, 0.87);
      padding: 2.4rem;
    }
  }

  &__Footer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 21rem;

    &--prev {
      transform: rotate(180deg);
    }
  }
}

// .slick-arrow {
//   z-index: 10;
//   width: 30px;
//   height: 30px;
//   border-radius: 50%;
// }

// .slick-arrow::before {
//   font-size: 28px;
// }

// .slick-prev {
//   left: 4px;
// }

// .slick-next {
//   right: 4px;
// }
