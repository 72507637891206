.BuyCourses {
  padding: 3.2rem;

  &__Header {
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 3.6rem;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 4rem;
  }

  &__Body {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    &--Img {
      width: 6%;
    }

    &--Para {
      font-style: normal;
      font-weight: 450;
      font-size: 1.8rem;
      line-height: 150%;
      color: rgba(0, 0, 0, 0.6);
      padding-bottom: 2.4rem;
      margin-left: 1.8rem;
    }

    &--Footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      &__Img {
        width: 15%;
        // margin-left: 4rem;
      }
    }
  }
}
