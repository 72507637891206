.CodingPartners {
  background-image: url("../../images/coding-store/bg.svg");
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 3.2rem 0;

  &__Header {
    font-style: normal;
    font-weight: bold;
    font-size: 2.4rem;
    line-height: 3.6rem;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 3.5rem;
  }

  &__Body {
    display: flex;
    flex-direction: row;
    padding: 0 2rem;

    &--Content {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__Img {
        width: 90%;
        margin-bottom: 1.6rem;
      }

      &__Para {
        width: 80%;
        font-weight: 450;
        font-size: 1.6rem;
        line-height: 150%;
        text-align: center;
        font-feature-settings: "liga" off;
        color: #18191f;
      }
    }
  }
}
