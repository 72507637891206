.faqContainer {
  padding: 9rem 10rem;
  background: #ffffff;

  @include respond(phone) {
    padding: 6rem 4rem;
  }
}

.faqContainer--heading {
  font-size: 3.2rem;
  line-height: 4.8rem;
  font-weight: $font-weight-bold;
  margin-bottom: 5rem;
  text-align: center;

  @include respond(phone) {
    font-weight: $font-weight-bold;
    font-size: 2.4rem;
  }
}

// .faq-heading {
//   font-weight: 400;
//   font-size: 19px;
//   -webkit-transition: text-indent 0.2s;
//   padding: 40px 20px;
//   text-indent: 20px;
//   color: #333;
//   display: flex;
//   align-items: center;
// }

// .faq-text {
//   font-weight: 400;
//   color: #919191;
//   width: 95%;
//   padding-left: 20px;
//   margin-bottom: 30px;
//   text-indent: 20px;
// }

// .faq {
//   width: 75%;
//   margin: 0 auto;
//   background: white;
//   border-radius: 4px;
//   position: relative;
//   border: none;
// }

// .faq label {
//   display: block;
//   position: relative;
//   overflow: hidden;
//   cursor: pointer;
//   height: 56px;
//   padding-top: 1px;

//   background-color: #ffffff;
//   border-bottom: 1px solid #e1e1e1;
// }

// .faq input[type="checkbox"] {
//   display: none;
// }

// .faq .faq-arrow {
//   width: 5px;
//   height: 5px;
//   transition: -webkit-transform 0.8s;
//   transition: transform 0.8s;
//   transition: transform 0.8s, -webkit-transform 0.8s;
//   -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
//   border-top: 2px solid rgba(0, 0, 0, 0.33);
//   border-right: 2px solid rgba(0, 0, 0, 0.33);
//   position: absolute;
//   right: 27px;
//   -webkit-transform: rotate(45deg);
//   transform: rotate(45deg);
// }

// .faq input[type="checkbox"]:checked + label > .faq-arrow {
//   transition: -webkit-transform 0.8s;
//   transition: transform 0.8s;
//   transition: transform 0.8s, -webkit-transform 0.8s;
//   -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
//   -webkit-transform: rotate(135deg);
//   transform: rotate(135deg);
// }
// .faq input[type="checkbox"]:checked + label {
//   display: block;
//   background: rgba(255, 255, 255, 255) !important;
//   color: #4f7351;
//   height: 225px;
//   height: auto;
//   transition: height 0.8s;
//   -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
// }

// .faq input[type="checkbox"]:not(:checked) + label {
//   display: block;
//   transition: height 0.8s;
//   height: 60px;
//   -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
// }

// ::-webkit-scrollbar {
//   display: none;
// }

.faq-heading {
  // font-family: Lato;
  // font-weight: 550;
  font-size: 1.8rem;
  -webkit-transition: text-indent 0.2s;
  text-indent: 2rem;
  color: #333;
  margin-bottom: 2rem;
  margin-top: 2rem !important;
  padding-right: 2rem;
  display: flex;
  justify-content: space-between;
  // align-items: center;
}

.faq-heading > div:nth-child(1) {
  display: flex;
  flex-direction: row;
  align-items: center;

  @include respond(phone) {
    text-indent: 0;
    width: 90%;
  }
}

.faq-heading > div:nth-child(1) > div:nth-child(1) {
  margin-bottom: 1rem;
}

.faq-heading > div:nth-child(1) > div:nth-child(2) {
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.faq-heading > div:nth-child(2) {
  margin-top: 0.7rem;
  @include respond(phone) {
    margin-top: 0.56rem;
  }
}

@media (max-width: 330px) {
  .faq-heading {
    margin-top: 1.2rem !important;
  }
  .faq-heading > div:nth-child(2) {
    margin-top: 1.5rem;
  }
}

.faq-text {
  font-weight: 400;
  font-size: 1.8rem;
  line-height: 129%;
  color: rgba(0, 0, 0, 0.6);
  width: 92%;
  padding-left: 6.5rem;
  margin-bottom: 3rem;

  @include respond(phone) {
    padding-left: 2.5rem;
  }
}

.faq {
  width: 75%;
  margin: 0 auto;
  // background: white;
  background-color: #ffffff;
  border-radius: 4px;
  position: relative;
  border: none;

  @include respond(phone) {
    width: 100%;
  }
}
.faq label {
  display: block;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  height: 5.6rem;
  padding-top: 1px;

  //   background-color: #ffffff;
  background-color: #ffffff;
  border-bottom: 1px solid #e1e1e1;
}

.faq input[type="checkbox"] {
  display: none;
}

.faq .faq-arrow {
  width: 5px;
  height: 5px;
  transition: -webkit-transform 0.8s;
  transition: transform 0.8s;
  transition: transform 0.8s, -webkit-transform 0.8s;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  border-top: 2px solid rgba(0, 0, 0, 0.33);
  border-right: 2px solid rgba(0, 0, 0, 0.33);
  // margin: 5px 0 0 2px;
  -webkit-transform: rotate(135deg);
  transform: rotate(135deg);

  @include respond(phone) {
  }
}

.faq input[type="checkbox"]:checked + label > .faq-heading > .faq-arrow {
  transition: -webkit-transform 0.8s;
  transition: transform 0.8s;
  transition: transform 0.8s, -webkit-transform 0.8s;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
  -webkit-transform: rotate(315deg);
  transform: rotate(315deg);
}
.faq input[type="checkbox"]:checked + label {
  display: block;
  background: #ffffff;
  color: #4f7351;
  height: 20rem;
  transition: height 0.8s;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);

  @include respond(phone) {
    height: 24rem;
  }
}

.faq input[type="checkbox"]:not(:checked) + label {
  display: block;
  background: #ffffff;
  transition: height 0.8s;
  height: 6rem;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.faq #faq-b:checked + label,
.faq #faq-a:checked + label,
.faq #faq-c:checked + label {
  height: 16rem;
  background: #ffffff;
}

.faq #faq-d:checked + label {
  height: 12rem;
}

.faq #faq-e:checked + label {
  height: 12rem;
}

.faq #faq-f:checked + label {
  height: 13rem;
}

.faq #faq-g:checked + label {
  height: 18rem;
}

.faq #faq-i:checked + label,
.faq #faq-j:checked + label {
  height: 16rem;
}

// .faq #faq-c:checked + label {
//   height: 12rem;
//   background: #ffffff;
// }

@include respond(phone) {
  .faq #faq-c:not(:checked) + label,
  .faq #faq-e:not(:checked) + label {
    height: 7rem;
  }

  .faq #faq-c:not(:checked) + label > .faq-text {
    margin-top: 3rem;
  }

  .faq #faq-d:not(:checked) + label {
    height: 7rem;
  }

  .faq #faq-a:not(:checked) + label {
    height: 7rem;
  }

  .faq #faq-a:checked + label {
    height: 18rem;
  }

  .faq #faq-b:checked + label {
    height: 24rem;
  }

  // .faq #faq-b:checked + label {
  //   height: 25rem;
  // }

  .faq #faq-c:checked + label {
    height: 18rem;
  }

  .faq #faq-d:checked + label,
  .faq #faq-e:checked + label {
    height: 23rem;
  }

  .faq #faq-f:checked + label {
    height: 18rem;
  }

  .faq #faq-g:checked + label,
  .faq #faq-h:checked + label {
    height: 34rem;
  }

  .faq #faq-i:checked + label {
    height: 26rem;
  }

  .faq #faq-j:checked + label {
    height: 20rem;
  }
  .faq #faq-k:checked + label {
    height: 26rem;
  }
}

::-webkit-scrollbar {
  display: none;
}

.moreFAQ {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 3rem;

  &__btn {
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    line-height: 150%;
    text-align: center;
    color: #009ae0;
    background: #ffffff;
    padding: 1rem 4rem;
    border: 2px solid #009ae0;
    border-radius: 4px;
    @include respond(phone) {
      width: 100%;
      font-size: 2rem;
      padding: 0.8rem 0rem;
      margin-top: 1rem;
    }
  }
}
