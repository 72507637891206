.SuccessMsg {
  &__modalSuccess {
    display: none;
    position: fixed;
    z-index: 100000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.8);

    @include respond(phone) {
      display: flex;
      display: -webkit-flexbox;
    }
  }

  @include respond(phone) {
    display: flex;
    display: -webkit-flexbox;
    position: fixed;
    z-index: 100000;
    left: 0;
    top: 0;
    width: 100%;
    // height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.1);
  }

  &__modalContainerSuccess {
    background-color: #ffffff;
    margin: auto;
    border: 0.1rem solid #888;
    width: 40%;
    border-radius: 1%;

    @include respond(phone) {
      background-color: #ffffff;
      border: none;
      width: 100%;
      border-radius: 12px 12px 0px 0px;
      position: absolute;
      bottom:0;
    }
  }

  &__modalBodySuccess {
    padding: 2.4rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 0.1rem solid #eee;

    @include respond(phone) {
      padding: 2.4rem;
      display: flex;
      flex-direction: column;
      border-bottom: none;
    }

    &--Header {
      font-size: 1.8rem;
      line-height: 150%;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.87);
      mix-blend-mode: normal;
      margin-bottom: 1.6rem;
    }

    &--SubHeader {
      font-weight: 450;
      font-size: 1.6rem;
      line-height: 150%;
      color: rgba(0, 0, 0, 0.6);
      mix-blend-mode: normal;
      margin-bottom: 2.4rem;
    }

    &--Row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      
      &__Content {
        border: 1px solid #2c7478;
        box-sizing: border-box;
        border-radius: 2px;
        padding: 1.2rem;
        font-style: normal;
        font-weight: 450;
        font-size: 1.4rem;
        line-height: 1.8rem;
        color: #2c7478;
        margin-bottom: 1.2rem;
      }
    }

    &--Button {
      width: 100%;
      position: fixed;
      bottom: 0;
      padding-bottom: 2.4rem;
      display: flex;
      justify-content: center;

      &__btn {
        width: 90%;
        font-style: normal;
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 24px;
        padding: 1.2rem 0;
        text-align: center;
        color: #ffffff;
        background: #529fa4;
        border-radius: 4px;
      }
    }

    &--successPara {
      font-size: 2.4rem;
      font-weight: bold;
      line-height: 3.6rem;
      text-align: center;
      margin-top: 3rem;
      color: rgba(0, 0, 0, 0.87);

      @include respond(phone) {
        padding: 0 5rem;
        font-weight: 550;
        font-size: 2rem;
        line-height: 3rem;
        text-align: center;
        color: #2d2d45;
      }
    }

    &--successClose {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      @include respond(phone) {
        margin-bottom: 4rem;
      }
    }

    &--successClose img {
      cursor: pointer;
    }

    &--img {
      width: 100%;
      @include respond(phone) {
        margin-top: 5rem;
      }
    }
  }
}

@media (max-width: 330px) {
  .SuccessMsg {
    &__modalContainerSuccess {
      margin-top: 20rem;
    }
  }
}

.modalSuccess {
  padding: 2.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &--Close {
    width: 100%;
  }

  &--Close img {
    float: right;
  }

  &--Header {
    margin: 2.4rem 0;
  }

  &--ThankYou {
    font-weight: 450;
    font-size: 2.2rem;
    line-height: 150%;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 1.6rem;
  }

  &--Enabled {
    font-weight: 450;
    font-size: 1.8rem;
    line-height: 150%;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);
    margin-bottom: 2.7rem;
  }

  &--Team {
    font-weight: 450;
    font-size: 1.4rem;
    line-height: 150%;
    text-align: center;
    color: #2c7478;
    background: rgba(44, 116, 120, 0.04);
    border: 1px solid #2c7478;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 1.2rem 10rem;
    margin-bottom: 5.6rem;
  }
}
