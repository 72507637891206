.landing {
  background: linear-gradient(0deg, #f7fafc, #f7fafc), #f7fafc;
  background-image: url("../../images/affiliate-v2/background.svg");
  background-size: cover;
  background-position: center;
  margin-top: 6rem;
  display: grid;
  grid-template-columns: 2fr 2fr;

  @include respond(phone) {
    grid-template-columns: 2fr;
    // background-image: url("../../images/affiliate-v2/mobileBg.svg");
  }

  &__footer {
    z-index: 10;
    width: 100%;
    background: #ffffff;
    position: fixed;
    bottom: 0;
    padding: 1.2rem 0;
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.24);
    display: flex;
    justify-content: center;

    &--btn {
      font-style: normal;
      font-weight: 500;
      font-size: 1.2rem;
      line-height: 150%;
      color: #ffffff;
      background: #529FA4;
      box-shadow: 0px 1px 4px rgba(0, 154, 224, 0.25);
      border-radius: 4px;
      padding: 1.1rem 4rem;

      @include respond(phone) {
        width: 75%;
        font-weight: 600;
        font-size: 1.8rem;
      }
    }

    &--btn:hover {
      cursor: pointer;
    }
  }

  &__col1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 0 10rem;
    background-image: url("../../images/coding-store/rsz_1background.jpg");

    &--para {
      font-weight: bold;
      line-height: 150%;
      text-align: center;
      color: #ffffff;
      font-size: 3.2rem;
      margin-bottom: 1.8rem;
    }

    &--para2 {
      font-weight: 450;
      font-size: 1.8rem;
      line-height: 150%;
      text-align: center;
      color: #ffffff;
    }

    &--btn {
      margin-top: 4rem;
      font-style: normal;
      font-weight: 500;
      font-size: 2.4rem;
      line-height: 150%;
      color: #ffffff;
      background: #75e3ea;
      box-shadow: 0px 1px 4px rgba(0, 154, 224, 0.25);
      border-radius: 4px;
      padding: 1.1rem 0rem;
      color: rgba(0, 0, 0, 0.87);

      @include respond(phone) {
        width: 100%;
        margin-bottom: 4rem;
        font-weight: 600;
        font-size: 1.8rem;
      }
    }

    &--btn:hover {
      cursor: pointer;
    }

    @include respond(phone) {
      padding: 4rem 3.2rem 36rem;
      justify-content: none;
    }
  }
  &__col2 {
    padding-top: 6rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    &--img {
      width: 60%;
      height: auto;
    }

    @include respond(phone) {
      display: none;
    }
  }

  &__col3 {
    width: 100%;
    display: flex;
    padding-top: 0rem;
    flex-direction: row;
    justify-content: center;
    &--img {
      width: 100%;
      height: auto;
    }
  }
}
